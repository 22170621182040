<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户名" prop="userName">
        <!-- <a-input v-model="form.userName" placeholder="请输入用户名" /> -->
        {{ form.userName }}
      </a-form-model-item>
      <a-form-model-item label="用户昵称" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入用户名" />
      </a-form-model-item>
      <a-form-model-item label="所属驿站" prop="postStationId">
        <a-select placeholder="请选择所属驿站" v-model="form.postStationId" @change="handleChange">
          <a-select-option v-for="(item, index) in stationList" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="角色" prop="roleIds">
        <a-select mode="multiple" v-model="form.roleIds" placeholder="请选择">
          <a-select-option v-for="(d, index) in roleOptions" :key="index" :value="d.roleId">
            {{ d.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所辖驿站" v-if="form.roleIds && form.roleIds.includes(100)" prop="manageStations">
        <a-select
          placeholder="请选择所辖驿站"
          mode="multiple"
          v-model="manageStationsArr"
          @select="manageSelect"
          @change="manageHandleChange"
        >
          <a-select-option v-for="(item, index) in manageStationList" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm" :loading="saveLoading"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUsers, addUsers, updateUsers } from '@/api/recyclable/users'
import { listAll } from '@/api/recyclable/postStationInfo'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      saveLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        username: null,
        password: null,
        tenantCode: null,
        postStationId: null,
        status: 0,
        roleIds: undefined,
        manageStations: undefined
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      stationList: [],
      manageStationList: [],
      roleOptions: [],
      manageStationsArr: undefined
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {
    this.listPostStationInfo()
  },
  methods: {
    listPostStationInfo() {
      listAll({}).then(res => {
        this.stationList = res.data
        this.manageStationList = JSON.parse(JSON.stringify(res.data))
        this.manageStationList.unshift({
          id: 'all',
          name: '全部'
        })
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        username: null,
        password: null,
        tenantCode: null,
        postStationId: null,
        status: 0,
        roleIds: undefined,
        stationIds: undefined
      }
      this.manageStationsArr = undefined
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      getUsers().then(response => {
        this.roleOptions = response.roles
        this.open = true
        this.formTitle = '添加'
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.userId : ids
      getUsers(id).then(response => {
        this.form = response.data
        this.roleOptions = response.roles
        this.$set(this.form, 'roleIds', response.roleIds)
        this.open = true
        this.formTitle = '修改'
        if (response.stations) {
          const manageStationsArr = []
          for (const item of response.stations) {
            manageStationsArr.push(item.id)
          }
          this.manageStationsArr = manageStationsArr
        }
      })
    },
    handleChange(e) {
      console.log('handleChange', e)
      // if (e.includes('all')) {
      //   this.form.postStationId = ['all']
      //   this.form.stationName = '全部'
      //   return
      // }
      this.form.postStationId = e
      for (const item of this.stationList) {
        if (item.id === e) {
          this.form.stationName = item.name
        }
      }
    },
    setAllManageArr() {
      const allArr = []
      for (const item of this.stationList) {
        if (item.id !== 'all') {
          allArr.push(item.id)
        }
      }
      return allArr
    },
    manageHandleChange(e) {
      console.log('manageHandleChange', e)
      this.$nextTick(() => {
        if (this.manageSelectValue === 'all') {
          this.manageStationsArr = ['all']
          this.setAllManageArr()
          const all = this.setAllManageArr()
          this.form.stationIds = all
          this.manageSelectValue = ''
          return
        }
        const arr = e
        const i = arr.indexOf('all')
        if (i > -1) {
          arr.splice(i, 1)
        }
        this.manageStationsArr = arr
        this.form.stationIds = arr
      })
    },
    manageSelect(e) {
      console.log('manageSelect', e)
      this.manageSelectValue = e
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          if (this.form.userId !== undefined && this.form.userId !== null) {
            updateUsers(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addUsers(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
