var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户名", prop: "userName" } },
            [_vm._v(" " + _vm._s(_vm.form.userName) + " ")]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户昵称", prop: "nickName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入用户名" },
                model: {
                  value: _vm.form.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickName", $$v)
                  },
                  expression: "form.nickName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属驿站", prop: "postStationId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择所属驿站" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.form.postStationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "postStationId", $$v)
                    },
                    expression: "form.postStationId",
                  },
                },
                _vm._l(_vm.stationList, function (item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "角色", prop: "roleIds" } },
            [
              _c(
                "a-select",
                {
                  attrs: { mode: "multiple", placeholder: "请选择" },
                  model: {
                    value: _vm.form.roleIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleIds", $$v)
                    },
                    expression: "form.roleIds",
                  },
                },
                _vm._l(_vm.roleOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.roleId } },
                    [_vm._v(" " + _vm._s(d.roleName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.roleIds && _vm.form.roleIds.includes(100)
            ? _c(
                "a-form-model-item",
                { attrs: { label: "所辖驿站", prop: "manageStations" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择所辖驿站",
                        mode: "multiple",
                      },
                      on: {
                        select: _vm.manageSelect,
                        change: _vm.manageHandleChange,
                      },
                      model: {
                        value: _vm.manageStationsArr,
                        callback: function ($$v) {
                          _vm.manageStationsArr = $$v
                        },
                        expression: "manageStationsArr",
                      },
                    },
                    _vm._l(_vm.manageStationList, function (item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.saveLoading },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }